import React from "react";
import { blog } from "../../dummydata";
import { useHistory } from "react-router-dom";


const BlogCard = () => {
  const history = useHistory();

  const handleblogclick= (index) => {
    index = blog.length - index - 1;
    history.push(`/blog/${index + 1}`);
  };

  return (
    <>
      {blog.slice().reverse().map((val, index) => (
        <div className="items shadow" key={index}>
          <div className="img">
            <img src={val.cover} alt="" />
          </div>
          <div className="text">
            <div className="admin flexSB">
              <span>
                <i className="fa fa-user"></i>
                <label htmlFor="">{val.type}</label>
              </span>
              <span>
                <i className="fa fa-calendar-alt"></i>
                <label htmlFor="">{val.date}</label>
              </span>
            </div>
            <h1 onClick={() => handleblogclick(index)}>{val.title}</h1>
            <p>{val.desc}</p>
            <button onClick={() => handleblogclick(index)}>Read More</button>
          </div>
        </div>
      ))}
    </>
  );
};

export default BlogCard;
