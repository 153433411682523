import React, {useState, useEffect} from "react";
import "../CourseDetail.css"
import Heading from "../../common/heading/Heading"
import logo from "./assets/git.png"

const Git = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
  }, []);

  /*--------------- Add toggle button for curriculum ---------------*/
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };
  /*----------------------------------------------------------------*/

  return (
	<>
    <div className="container ">
      <section className='info'>
        <div className='info-body'>
        
        <Heading title="Introduction to Git " />
        <img src={logo} alt="" />
        <br />
        <br />

          <p>Welcome to our Version Control Systems Course! This course is designed to equip you with the essential 
              skills and knowledge needed to work with large software development teams effectively. Whether you're a beginner or an 
              experienced developer looking to enhance your expertise, this course caters to all levels.
          </p>

          <br />
          <h2>Course Overview</h2>

          <p><strong>Duration</strong>: 2 days [ weekend batch ]<br />
          <strong>Format</strong>: Online/In-person</p>
          <br />
          <h2 style={{ fontSize: "29px", textDecoration: "underline" }}>Course Curriculum</h2>
          <br />

          <button className='accordion flexSB' onClick={() => toggleAccordion(0)}>
          <h2>Day-1: Understanding Version Control and Introduction to Git</h2>
            <span>{activeIndex === 0 ? <i className='fa fa-chevron-down'></i> : <i className='fa fa-chevron-right'></i>}</span>
          </button>
          {activeIndex === 0 && (
            <div className='text'>
              <ol>
                  <li>What is version control?</li>
                  <li>Importance of version control in software development</li>
                  <li>Overview of Git and its features</li>
                  <li>Basic Git concepts: repository, commit, branch, merge, etc.</li>
                  <li>Installing Git on different platforms</li>
                  <li>Initializing a Git repository, committing changes, viewing history</li>
                  <li>Understanding branches, merging branches, resolve merge conflicts</li>
              </ol>
            </div>
          )}

          <button className='accordion flexSB' onClick={() => toggleAccordion(1)}>
          <h2>Day-2: Collaborating with Git and Advance topics</h2>
            <span>{activeIndex === 1 ? <i className='fa fa-chevron-down'></i> : <i className='fa fa-chevron-right'></i>}</span>
          </button>
          {activeIndex === 1 && (
            <div className='text'>
              <ol>
                  <li>Cloning remote repositories, pulling and pushing changes</li>
                  <li>Collaborating with others using branches</li>
                  <li>Rebasing and cherry-picking</li>
                  <li>Working with submodules, git hooks and customization</li>
                  <li>Git hosting platform : Github workflows</li>
                  <li>Resources for further learning and exploration</li>
              </ol>
            </div>
          )}
          <br />
          <br />

          <h2>Free course material</h2>
          <br />
          <a href="./assets/PenguinTech-resources-Git.zip" download className="download-link">Download Resources</a>
          <br />
          <br />
        </div>
      </section>
      
    </div>
  </>
  );
};

export default Git;
