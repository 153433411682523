/*teams*/
import t1 from "./assests/team/t1.webp";
import t2 from "./assests/team/t2.webp";

/*services*/
import o1 from "./assests/courses/online/o12.png";
import o1_1 from "./assests/courses/online/o12.1.png";
import o2 from "./assests/courses/online/o11.png";
import o2_1 from "./assests/courses/online/o11.1.png";
import o3 from "./assests/courses/online/o3.png";
import o3_1 from "./assests/courses/online/o3.1.png";
import o4 from "./assests/courses/online/o4.png";
import o4_1 from "./assests/courses/online/o4.1.png";
import o5 from "./assests/courses/online/o5.png";
import o5_1 from "./assests/courses/online/o5.1.png";
import o6 from "./assests/courses/online/o6.png";
import o6_1 from "./assests/courses/online/o6.1.png";

/*courses*/
import c1 from "./assests/courses/yocto-project.png";
import c2 from "./assests/courses/docker.png";
import c3 from "./assests/courses/git.png";


/*blogs*/
import sentry from "./assests/blog/sentry.jpg"
import pstore from "./assests/blog/pstore.jpg"
import boot from "./assests/blog/boot.jpg"
import distros from "./assests/blog/distros.png"
import yocto from "./assests/blog/yocto.jpg"
import error from "./assests/blog/error.jpg"
import BRT from "./assests/blog/BRT.webp"

export const socialMediaLinks = [
  {
    youtube: "https://www.youtube.com/@linuxexplorer",
    github: "https://github.com/prashantdivate",
    linkedin: "https://www.linkedin.com/in/prashant-divate/",
    gmail: "diwateprashant44@gmail.com",
    twitter: "https://twitter.com/DivatePrashant",
  },
];

export const homeAbout = [
  {
    id: 1,
    cover: "https://img.icons8.com/?size=160&id=101827&format=png",
    title: "Unparallel Industry Knowledge",
    desc: "Our team brings years of experience and deep understanding of Embedded Systems to the table. Benefit from our insights and stay ahead in the competitive landscape.",
  },
  {
    id: 2,
    cover: "https://img.icons8.com/?size=100&id=101797&format=png&color=000000",
    title: "Faster New Project Development",
    desc: "We will help you to reuse components, CI Setup and build infrastructure across projects which leads to Lower Development Cost with less resource required.",
  },
  {
    id: 3,
    cover: "https://img.icons8.com/?size=100&id=53376&format=png&color=000000",
    title: "Easy to maintain",
    desc: "For more than one devices, we will help you to maintain the software with ease. Bugs Fixes across the Platform – Fix one Fix all.",
  },
]
export const awrapper = [
  {
    cover: "https://img.icons8.com/external-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto/80/ffffff/external-graduation-education-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto.png",
    data: "30",
    title: "SUCCESS STORIES",
  },

  {
    cover: "https://img.icons8.com/ios/80/ffffff/athlete.png",
    data: "2",
    title: "TRUSTED TUTORS",
  },
  {
    cover: "https://img.icons8.com/external-outline-icons-maxicons/80/ffffff/external-calender-insurance-outline-outline-icons-maxicons.png",
    data: "10",
    title: "SCHEDULES",
  },
  {
    cover: "https://img.icons8.com/ios/80/ffffff/macbook-idea--v3.png",
    data: "5",
    title: "COURSES",
  },
]
export const coursesCard = [
  {
    id: 1,
    cover: c1,
    coursesName: "Embedded Linux Development",
    courTeacher: [
      {
        name: "by Prashant Divate",
        totalTime: "50 lectures (190 hrs)",
      },
    ],
  },
  {
    id: 2,
    cover: c2,
    coursesName: "Introduction to docker containers",
    courTeacher: [
      {
        name: "by Prashant Divate",
        totalTime: "30 lectures (125 hrs)",
      },
    ],
  },
  {
    id: 3,
    cover: c3,
    coursesName: " Introduction to Git and Github",
    courTeacher: [
      {
        name: "by Prashant Divate",
        totalTime: "50 lectures (50 hrs)",
      },
    ],
  },/*
  {
    id: 4,
    cover: "./assests/courses/c4.png",
    coursesName: "Introducing to Programming ",
    courTeacher: [
      {
        dcover: "./assests/back.webp",
        name: "by Prabin Thapa Mager",
        totalTime: "20 lectures (20 hrs)",
      },
    ],
    priceAll: "₹30 All Course",
    pricePer: "₹3 per month",
  },
  {
    id: 5,
    cover: "./assests/courses/c5.png",
    coursesName: "Introducing to ReactJS",
    courTeacher: [
      {
        dcover: "./assests/back.webp",
        name: "by Sunil Bishowkaram",
        totalTime: "100 lectures (150 hrs)",
      },
    ],
    priceAll: "$300 All Course",
    pricePer: "$30 per month",
  },
  {
    id: 6,
    cover: "./assests/courses/c6.png",
    coursesName: "Learn Frontend Programming Language",
    courTeacher: [
      {
        dcover: "./assests/back.webp",
        name: "by Sunil Bishowkaram",
        totalTime: "200 lectures (300 hrs)",
      },
    ],
    priceAll: "$500 All Course",
    pricePer: "$80 per month",
  },
  {
    id: 7,
    cover: "./assests/courses/c7.png",
    coursesName: "Introducing to with HTML / CSS",
    courTeacher: [
      {
        dcover: "./assests/back.webp",
        name: "by Price",
        totalTime: "20 lectures (50 hrs)",
      },
    ],
    priceAll: "$20 All Course",
    pricePer: "$2 per month",
  },
  {
    id: 8,
    cover: "./assests/courses/c8.png",
    coursesName: "Introducing to with JAVA",
    courTeacher: [
      {
        dcover: "./assests/back.webp",
        name: "by Petter",
        totalTime: "80 lectures (200 hrs)",
      },
    ],
    priceAll: "$200 All Course",
    pricePer: "$40 per month",
  },
  {
    id: 9,
    cover: "./assests/courses/c9.png",
    coursesName: "Introducing to with HTML / CSS / JAVASCRIPT",
    courTeacher: [
      {
        dcover: "./assests/back.webp",
        name: "by Petter",
        totalTime: "50 lectures (50 hrs)",
      },
    ],
    priceAll: "$20 All Course",
    pricePer: "$3 per month",
  },*/
]
export const service = [
  {
    cover: o6,
    hoverCover: o6_1,
    serviceName: "Board bringup",
    desciption: "Provide support in evaluating new chip or board bringup from scratch",
  },
  {
    cover: o2,
    hoverCover: o2_1,
    serviceName: "Unified OS platform",
    desciption: "Unified OS platform with multi arch support in single distro",
  },
  {
    cover: o3,
    hoverCover: o3_1,
    serviceName: "Toolchain development",
    desciption: "Develops common SDK for all project to streamline software development",
  },
  {
    cover: o1,
    hoverCover: o1_1,
    serviceName: "Virtual CI/CD development",
    desciption: "Cloud based CI/CD pipeline to make it on-board new projects using Microsoft Azure, Bitbucket bamboo and Github Actions",
  },
  {
    cover: o5,
    hoverCover: o5_1,
    serviceName: "Software Integration",
    desciption: "provide support for setting up software integration or open source projects integration",
  },
  {
    cover: o4,
    hoverCover: o4_1,
    serviceName: "Automated testing",
    desciption: "Support Automated test validation for every build with accuracy",
  },
  /*{
    cover: "./assests/courses/online/o7.png",
    hoverCover: "./assests/courses/online/o7.1.png",
    courseName: "Health & Fitness",
    course: "10 Courses",
  },
  {
    cover: "./assests/courses/online/o8.png",
    hoverCover: "./assests/courses/online/o8.1.png",
    courseName: "Marketing",
    course: "30 Courses",
  },
  {
    cover: "./assests/courses/online/o9.png",
    hoverCover: "./assests/courses/online/o9.1.png",
    courseName: "Graphic Design",
    course: "80 Courses",
  },
  {
    cover: "./assests/courses/online/o10.png",
    hoverCover: "./assests/courses/online/o10.1.png",
    courseName: "Music",
    course: "120 Courses",
  },
  {
    cover: "./assests/courses/online/o11.png",
    hoverCover: "./assests/courses/online/o11.1.png",
    courseName: "Business Administration",
    course: "17 Courses",
  },
  {
    cover: "./assests/courses/online/o12.png",
    hoverCover: "/assests/courses/online/o12.1.png",
    courseName: "Web Management",
    course: "17 Courses",
  },*/
]
export const team = [
  {
    cover: t1,
    name: "Prashant Divate",
    work: "Developer And Tech Leader",
  },
  {
    cover: t2,
    name: "Ph.D Brian Sebastian",
    work: "Board Advisor",
  },/*
  {
    cover: t3,
    name: "Ph.D Anna Hanzen",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: t4,
    name: "Ph.D Brian Wooden",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: t5,
    name: "Ph.D Adrian Molises",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: t6,
    name: "Ph.D Arthur MaGregor",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: t7,
    name: "Ph.D Anna Hanzen",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: t8,
    name: "Ph.D Brian Wooden",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },*/
]

export const faq = [
  {
    title: "How to Enroll the Trainings?",
    desc: "Go to the services pages and for appropriate course click on the ENROLL NOW button, submit the details.",
  },
  {
    title: "Why is board bringup important?",
    desc: "Board bringup ensures that new hardware components function correctly and are compatible with the desired software environment.",
  },
  {
    title: "What are the benefits of a common OS platform?",
    desc: "A common OS platform simplifies software development, deployment, and maintenance by providing a consistent environment across different hardware architectures.",
  },
  {
    title: "What is an SDK (Software Development Kit)?",
    desc: "An SDK is a set of tools, libraries, and documentation that developers use to create software applications for a specific platform.",
  },
  {
    title: "How do you implement virtual CI/CD?",
    desc: "We leverage cloud-based CI/CD pipelines, such as Microsoft Azure, Bitbucket Bamboo, and GitHub Actions, to enable rapid and reliable software development and deployment.",
  },
  {
    title: "How do you support software integration?",
    desc: "We provide expert assistance in setting up software integration solutions and integrating open-source projects, ensuring seamless operation and compatibility.",
  },
]
export const blog = [
  {
    id: 1,
    type: "Prashant Divate",
    date: "Jan. 7, 2024",
    com: "5 COMMENTS ",
    title: "Embedded Linux booting process in detail",
    desc: "This article will walk you through every step of the process of Embedded Linux booting. This is meant for engineers who are....",
    cover: boot,
  },
  {
    id: 2,
    type: "Prashant Divate",
    date: "MAR. 23, 2024",
    com: "3 COMMENTS ",
    title: "Log kernel crash using ramoops and pstore",
    desc: "Even though the Linux kernel is among the most reliable, crashes can still happen, either because of a client or proprietary...",
    cover: pstore,
  },
  {
    id: 3,
    type: "Prashant Divate",
    date: "MAY. 12, 2024",
    com: "3 COMMENTS ",
    title: "Setting Up sentry self-hosted server for error tracing",
    desc: "Since Sentry is an open-source system, the code is available online to deploy on your own infrastructure.In this post we are...",
    cover: sentry,
  },
  {
    id: 4,
    type: "Prashant Divate",
    date: "MAY. 14, 2024",
    com: "3 COMMENTS ",
    title: "Exploring the Best Linux Distros for Every Need: From Arch Linux to Red Hat Enterprise Linux",
    desc: "Linux, a versatile and powerful operating system, has gained immense popularity...",
    cover: distros,
  },
  {
    id: 5,
    type: "Prashant Divate",
    date: "MAY. 15, 2024",
    com: "3 COMMENTS ",
    title: "The Ultimate Guide to Yocto: Building Custom Linux Distributions for Embedded Systems",
    desc: "Welcome to the ultimate guide to Yocto! In this comprehensive blog post, we'll explore everything you need to know....",
    cover: yocto,
  },
  {
    id: 6,
    type: "Prashant Divate",
    date: "MAY. 18, 2024",
    com: "12 COMMENTS ",
    title: "Troubleshooting Common Issues in Linux Development Environments",
    desc: "As a developer, encountering errors and issues in your Linux environment can be a frustrating experience. Whether you are...",
    cover: error,
  },
  {
    id: 7,
    type: "Prashant Divate",
    date: "JUN. 12, 2024",
    com: "12 COMMENTS ",
    title: "Getting started with Buildroot",
    desc: "Buildroot, a toolkit for building highly customized embedded Linux images. Buildroot is the right combination of “powerful” and “easy to use.” It provides a lot of automation so that your build process can Just Work, freeing you up for...",
    cover: BRT,
  },
  {
    id: 8,
    type: "Prashant Divate",
    date: "JUL. 4, 2024",
    com: "3 COMMENTS ",
    title: "Upgrading to Yocto langdale",
    desc: "The Yocto 4.1 release (fall 2022), also codenamed “Langdale” introduces a couple of changes.The following errors and fixes have appeared while attempting to update a meta-layer to the current release.....",
    cover: yocto,
  },
]
export const testimonal = [
  {
    id: 1,
    name: "Sudheer Joona",
    post: "Software developer",
    desc: "It is perfect course - very comprehensible based on a practical work done step by step",
  },
  {
    id: 2,
    name: "Kumar Ashish",
    post: "Tech Lead",
    desc: "This 3-days series teaches Yocto comprehensively, very helpful if you want to get straight to specific Yocto topics.",
  },
  {
    id: 3,
    name: "Dmitry Zhuravskiy",
    post: "Developer",
    desc: "Cause and effect of recipes configurations are clearly demonstrated. Trainer knows his Yocto in and out !!!",
  },
]
