import React, {useRef} from "react"
import "./footer.css"
import "../header/SocialMedia.css";
import { socialMediaLinks } from "../../../dummydata";
import emailjs from "emailjs-com";

const Footer = () => {

  //const form = useRef();
  const emailRef = useRef();

  const serviceID = "service_e9srhaj";
  const templateID = "template_ru14an4";
  const userID = "TT6uRLKbvTYo5Za4g";

    const handleSubscribe = (e) => {
      // Check if the event target is the email input field
      if (e.target.tagName.toLowerCase() === "input") {
        return;
      }

      const userEmail = emailRef.current.value.trim(); // Trim whitespace from email address
      const subject = document.getElementById("subject").value;
      const message = document.getElementById("message").value;

      // Check if email is valid
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email regex
      if (!emailRegex.test(userEmail)) {
        alert("Please enter a valid email address.");
        return;
      }

      // Send email using EmailJS
      emailjs
        .send(serviceID, templateID, { user_email: userEmail, subject, message }, userID)
        .then(
          (result) => {
            console.log("Email sent successfully:", result.text);
            alert("Thank you for subscribing!");
            emailRef.current.value = ""; // Clear input field after successful subscription
          },
          (error) => {
            console.error("Email send failed:", error.text);
            alert("Failed to subscribe. Please try again later.");
          }
        );
    };

  return (
    <>
      <footer className="footer" style={{marginTop:"5%"}}>
        <div className="footer-container">
          <div className="row">
          <div className="footer-col">
              <h1>PenguinTech</h1>
              <p style={{color:"grey"}}>Work towards improving the products</p>

              <div className="social-media-div">
                {Object.keys(socialMediaLinks[0]).map((socialMedia, index) => (
                  socialMedia === "gmail" ? (
                    <a
                      key={index}
                      href={`mailto:${socialMediaLinks[0][socialMedia]}`}
                      className={`icon-button google`}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Google"
                    >
                      <i className={`fab fa-google`}></i>
                      <span></span>
                    </a>
                  ) : (
                    <a
                      key={index}
                      href={socialMediaLinks[0][socialMedia]}
                      className={`icon-button ${socialMedia}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label={socialMedia}
                    >
                      <i className={`fab fa-${socialMedia}`}></i>
                      <span></span>
                    </a>
                  )
                ))}
              </div>
            </div>
            <div className="footer-col">
              <h4>company</h4>
              <ul>
                <li><a href="/">Home</a></li>
                <li><a href="/services">our services</a></li>
                <li><a href="/trainings">trainings</a></li>
                <li><a href="/blogs">blogs</a></li>
                <li><a href="/about">about us</a></li>
              </ul>
            </div>
            <div className="footer-col">
              <h4>get help</h4>
              <ul>
                <li><a href="/faq">FAQ</a></li>
                <li><a href="/contact">Contact Us</a></li>
              </ul>
            </div>
            <div className="footer-col">
                <h4>subscribe</h4>
                <p style={{color:"white"}}>Don't miss to subscribe to our new feeds.</p>
                <br />
                <div className="subscribe-form">
                    <div onClick={handleSubscribe}>
                        <input type="text" ref={emailRef} name="user_email" placeholder="Enter email address" required/>
                        <input type="hidden" id="subject" name="subject" value="NewsLetter Subscription" required />
                        <input type="hidden" id="message" name="message" value="I want to subscribe your  NewsLetter" required />
                        <button type="submit" aria-label="Subscribe"><i class="fab fa-telegram-plane"></i></button>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </footer>
      <div className=' legal'>
        <p>
          Copyright ©2024 PenguinTech | Designed by Aarti Veer
        </p>
      </div>
    </>
  )
}

export default Footer
