import React from "react"
import "./about.css"
import Heading from "../common/heading/Heading"
import tagline from "../../assests/home/about.webp"

const About = () => {
  const numGalleries = 6;
  return (
    <>
      <Heading title='Our Expertise in' />
      <div className="boardcontainer">
        <div className="gallery">
          {Array.from({ length: numGalleries }, (_, i) => (
            <ul key={i}>
              <li>A</li>
              <li>B</li>
              <li>C</li>
              <li>D</li>
              <li>E</li>
              <li>6</li>
              <li>7</li>
              <li>8</li>
              <li>9</li>
            </ul>
          ))}
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />

      <Heading title='Our Vision' />
      <div className="imgcontainer" style={{alignItems:"center"}} >
        <img className="tagimage" src={tagline} alt='' />
      </div>

      <br />
      <br />
      <br />

      <Heading title='Our Mission' />
      <p style={{ textAlign:"center"}}>
        To equip individuals and organizations with cutting-edge technology knowledge and practical skills in Embedded Linux development, customization,
        <br />
        and optimization, enabling them to unleash the full potential of embedded devices and IoT solutions.
      </p>

    </>
  )
}

export default About
