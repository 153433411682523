// src/App.js
import React, { useState, useEffect } from "react";
import "./App.css";
import Header from "./components/common/header/Header";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import About from "./components/about/About";
import ServicesHome from "./components/services/ServicesHome";
import Trainings from "./components/training/TrainingsHome";
import Team from "./components/team/Team";
import FAQs from "./components/faq/FAQs";
import Blog from "./components/blog/Blog";
import Contact from "./components/contact/Contact";
import Footer from "./components/common/footer/Footer";
import Home from "./components/home/Home";
import Loader from "./components/common/loader/Loader";

// blogs pages
import Post1 from './components/blog/blog1/Post1';
import Post2 from './components/blog/blog2/Post2';
import Post3 from './components/blog/blog3/Post3';
import Post4 from './components/blog/blog4/Post4';
import Post5 from './components/blog/blog5/Post5';
import Post6 from './components/blog/blog6/Post6';
import Post7 from './components/blog/blog7/Post7';
import Post8 from './components/blog/blog8/Post8';

// courses pages
import Yocto from './components/training/yocto/yocto';
import Docker from './components/training/docker/docker';
import Git from './components/training/git/git';

import ReactGA from "react-ga4";

const TRACKING_ID = "G-VCW9GEYQ5B"
ReactGA.initialize(TRACKING_ID);

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    ReactGA.send({ hitType: "pageview", page: "/", title: "Home Page" });

    // Simulate a delay to show the loader (e.g., 2 seconds)
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <Router>
          <Header />
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/about' component={About} />
            <Route exact path='/services' component={ServicesHome} />
            <Route exact path='/team' component={Team} />
            <Route exact path='/trainings' component={Trainings} />
            <Route exact path='/faq' component={FAQs} />
            <Route exact path='/blogs' component={Blog} />
            <Route exact path='/contact' component={Contact} />
            <Route path="/blog/1" component={Post1} />
            <Route path="/blog/2" component={Post2} />
            <Route path="/blog/3" component={Post3} />
            <Route path="/blog/4" component={Post4} />
            <Route path="/blog/5" component={Post5} />
            <Route path="/blog/6" component={Post6} />
            <Route path="/blog/7" component={Post7} />
            <Route path="/blog/8" component={Post8} />
            <Route path="/course/1" component={Yocto} />
            <Route path="/course/2" component={Docker} />
            <Route path="/course/3" component={Git} />
          </Switch>
          <Footer />
        </Router>
      )}
    </>
  );
}

export default App;