import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Head from "./Head";
import "./header.css";

const Header = () => {
  const [click, setClick] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const location = useLocation();

  const isHomepage = location.pathname === "/";

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) { // Adjust this value as needed
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Head />
      <header className={isSticky ? "sticky" : ""}>
        <nav className='flexSB'>
          <ul className={click ? "mobile-nav" : "flexSB"} onClick={() => setClick(false)}>
            <li><a href="/" className={isHomepage ? (isSticky ? "black-text" : "black-text") : (isSticky ? "black-text" : "white-text")}>Home</a></li>
            <li><a href="/services" className={isHomepage ? (isSticky ? "black-text" : "black-text") : (isSticky ? "black-text" : "white-text")}>Services</a></li>
            <li><a href="/trainings" className={isHomepage ? (isSticky ? "black-text" : "black-text") : (isSticky ? "black-text" : "white-text")}>Trainings</a></li>
            <li><a href="/blogs" className={isHomepage ? (isSticky ? "black-text" : "black-text") : (isSticky ? "black-text" : "white-text")}>Blogs</a></li>
            <li><a href="/faq" className={isHomepage ? (isSticky ? "black-text" : "black-text") : (isSticky ? "black-text" : "white-text")}>FAQ</a></li>
            <li><a href="/contact" className={isHomepage ? (isSticky ? "black-text" : "black-text") : (isSticky ? "black-text" : "white-text")}>Contact</a></li>
          </ul>
          <div className='start'>
            <Link to='/services' className='button'>EXPLORE NOW</Link>
          </div>
          <button className='toggle' onClick={() => setClick(!click)} 
          aria-label={click ? 'Close menu' : 'Open menu'}>
            {click ? <i className='fa fa-times'> </i> : <i className='fa fa-bars'></i>}
          </button>
        </nav>
      </header>
    </>
  );
};

export default Header;
