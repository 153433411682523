import React, { useState } from 'react';
import ParticleEffectButton from 'react-particle-effect-button';
//import useSound from 'use-sound';
//import startSfx from '../sounds/start.mp3';

import "./Hero.css"

const Menu = ({ onClick }) => {
  const [startAnimation, setStartAnimation] = useState(false);
  const [isHidden, setIsHidden] = useState(false);

  const handleClick = () => {
    setStartAnimation(true);
  };

  //const [play] = useSound(startSfx);

  const scrollToAboutCard = () => {
    const aboutCardElement = document.getElementById("aboutCard");
    if (aboutCardElement) {
      aboutCardElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleAnimationComplete = () => {
    setIsHidden(true);
    console.log('animation completed');
    onClick();
  };

  return (
    <div >
      {!isHidden && (
        <ParticleEffectButton
          color="#f9748f"
          duration={800}
          easing="easeOutSine"
          hidden={startAnimation}
          onClick={handleClick}
          onComplete={handleAnimationComplete}
        >
          <button
            className="button"
            style={{ backgroundColor: '#FFD95A' }}
            onClick={() => {
              handleClick();
              //play();
              setTimeout(scrollToAboutCard, 1000);
            }}
          >
            GET STARTED<i className='fa fa-long-arrow-alt-right'></i>
          </button>
        </ParticleEffectButton>
      )}
    </div>
  );
};

export default Menu;
