import React from "react"
import Back from "../common/back/Back"
import Faq from "./Faq"

const Pricing = () => {
  return (
    <>
      <Back title='FAQs' color="#5ebaee"/>
      <Faq />
    </>
  )
}

export default Pricing
