import React, {useRef, useEffect, useState} from "react";
import "../CourseDetail.css"
import Heading from "../../common/heading/Heading"
import emailjs from "emailjs-com";
import logo from "../../../assests/services/Yocto_logo.webp"

const Yocto = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
  }, []);

  /*-------------------- submit form --------------------*/
  const form = useRef();

  const serviceID = "service_e9srhaj";
  const templateID = "template_ru14an4";
  const userID = "TT6uRLKbvTYo5Za4g";

  const handleSubmit = (e) => {
      e.preventDefault();
      // Send form data using EmailJS
      emailjs
        .sendForm(serviceID, templateID, e.target, userID)
        .then(
          (result) => {
            console.log("Email sent successfully:", result.text);
            // Handle success, e.g., show a success message to the user
            alert("Your message has been sent successfully!");
          },
          (error) => {
            console.error("Email send failed:", error.text);
            // Handle error, e.g., show an error message to the user
            alert("Failed to send message. Please try again later.");
          }
        );

        e.target.reset();
    };

    /*----------------------------------------------------------------*/
    /*--------------- Add toggle button for curriculum ---------------*/
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
      if (activeIndex === index) {
        setActiveIndex(null);
      } else {
        setActiveIndex(index);
      }
    };
    /*----------------------------------------------------------------*/
  return (
	<>
    <div className="container ">
      <section className='info'>
        
        <Heading title="Embedded Linux development " />
        <img src={logo} alt=""/>

        <div className='info-body'>
          <p>Welcome to our Embedded linux training! This comprehensive program is designed to equip you with the essential 
              skills and knowledge needed to work with Embedded Linux systems effectively. Whether you're a beginner or an 
              experienced developer looking to enhance your expertise, this course caters to all levels.
          </p>

          <br />
          <h2>Course Overview</h2>

          <p><strong>Duration</strong>: 3 days <br />
          <strong>Batches</strong>: Regular/Weekend <br />
          <strong>Format</strong>: Online or In-person for corporate companies<br />
          <strong>Fees</strong>: ₹ 6999</p>
          <br />
          <h2 style={{ fontSize: "29px", textDecoration: "underline" }}>Course Curriculum</h2>
          <br />

          <button className='accordion flexSB' onClick={() => toggleAccordion(0)}>
            <h2>Day-1: Introduction to Embedded Linux and Yocto Project</h2>
            <span>{activeIndex === 0 ? <i className='fa fa-chevron-down'></i> : <i className='fa fa-chevron-right'></i>}</span>
          </button>
          {activeIndex === 0 && (
            <div className='text'>
              <ol>
                  <li>Overview of Embedded Linux systems</li>
                  <li>What is Yocto project, history and advantages</li>
                  <li>Understanding the Yocto Project components: Poky, OpenEmbedded Core (OE-Core), Bitbake</li>
                  <li>Setting up the development environment: Installing required tools and dependencies</li>
                  <li>Yocto project workflow</li>
                  <li>Build the basic image and run in QEMU</li>
              </ol>
            </div>
          )}

          <button className='accordion flexSB' onClick={() => toggleAccordion(1)}>
            <h2>Day-2: Terminologies used in build</h2>
            <span>{activeIndex === 1 ? <i className='fa fa-chevron-down'></i> : <i className='fa fa-chevron-right'></i>}</span>
          </button>
          {activeIndex === 1 && (
            <div className='text'>
              <ol>
                  <li>What is Metadata, recipes and how to install package to image</li>
                  <li>what is conf files, classes, layers, images, packages</li>
                  <li>STM32MP157F-DK2 dev-kit</li>
                  <li>Build Yocto Image for STM32MP157F-DK2</li>
                  <li>Boot the image on the board</li>
              </ol>
            </div>
          )}

          <button className='accordion flexSB' onClick={() => toggleAccordion(2)}>
            <h2>Day-3: Test your image on dev-board</h2>
            <span>{activeIndex === 2 ? <i className='fa fa-chevron-down'></i> : <i className='fa fa-chevron-right'></i>}</span>
          </button>
          {activeIndex === 2 && (
            <div className='text'>
              <ol>
                  <li>Understand the booting sequence</li>
                  <li>Creating partitions and formatting the SD card</li>
                  <li>Copying images to the card</li>
                  <li>Hardware setup</li>
                  <li>Serial Setup</li>
                  <li>Booting STM32MP157F</li>
              </ol>
            </div>
          )}

          <p><strong> Add-on:</strong> Will teach you good practices while working on yocto project, how to enhance existing yocto infrastructure</p>
          <br />
          <h2>Who Should Attend?</h2>
          <p>This course is suitable for:</p>
          <ol>
              <li>Embedded system developers</li>
              <li>Software engineers</li>
              <li>Electronics engineers</li>
              <li>Students pursuing careers in embedded systems</li>
          </ol>

          <br />
          <h2>Prerequisites</h2>

          <br />
          <ol>
              <li>Version control with Git</li>
              <li>Compilers and build tools (GCC, make, cmake, autotools, etc).</li>
              <li>Embedded Linux architecture (toolchain, bootloader, kernel, rootfs).</li>
              <li>Basic Linux commands</li>
              <li>STM32MP157F-DK2 dev kit (optional)</li>
          </ol>
          
          <br />
          <h2>Course Benefits</h2>

          <br />
          <ol>
              <li>Gain practical skills to develop and deploy Embedded Linux systems.</li>
              <li>Enhance career prospects in the rapidly growing field of embedded systems.</li>
              <li>Hands-on experience with real-world projects.</li>
              <li>Access to resources and support from experienced instructors.</li>
          </ol>
          <br />
          <br />
        </div>
      </section>
      
        <section className="contact-form">
          <h2>ENROLL NOW</h2>
          <form ref={form} onSubmit={handleSubmit}>
            <label htmlFor="name">Name:</label>
            <input type="text" id="name" name="user_name" placeholder="Full Name" required />

            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="user_email" placeholder="Email" required />

            <label htmlFor="message">Message:</label>
            <textarea id="message" name="message" cols="30" rows="10" required></textarea>

            <input type="hidden" id="subject" name="subject" value="Enrollment to Yocto Course" required />

            <button type="submit">Submit</button>
          </form>
        </section>
    </div>
  </>
  );
};

export default Yocto;
