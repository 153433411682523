import React, {useRef, useEffect, useState} from "react";
import "../CourseDetail.css"
import Heading from "../../common/heading/Heading"
import emailjs from "emailjs-com";
import logo from "./assests/docker.png"

const Docker = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
  }, []);

  /*-------------------- submit form --------------------*/
  const form = useRef();

  const serviceID = "service_e9srhaj";
  const templateID = "template_ru14an4";
  const userID = "TT6uRLKbvTYo5Za4g";

  const handleSubmit = (e) => {
      e.preventDefault();
      // Send form data using EmailJS
      emailjs
        .sendForm(serviceID, templateID, e.target, userID)
        .then(
          (result) => {
            console.log("Email sent successfully:", result.text);
            // Handle success, e.g., show a success message to the user
            alert("Your message has been sent successfully!");
          },
          (error) => {
            console.error("Email send failed:", error.text);
            // Handle error, e.g., show an error message to the user
            alert("Failed to send message. Please try again later.");
          }
        );

        e.target.reset();
    };

    /*----------------------------------------------------------------*/
    /*--------------- Add toggle button for curriculum ---------------*/
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
      if (activeIndex === index) {
        setActiveIndex(null);
      } else {
        setActiveIndex(index);
      }
    };
    /*----------------------------------------------------------------*/
  return (
	<>
    <div className="container ">
	    <section className='info'>
        <Heading title="Introduction to docker containers" />
        <img src={logo} alt="" style={{marginTop:"-10%", marginBottom:"-10%"}}/>

        <div className='info-body'>
                <p>Welcome to our Introduction to docker containers Course! This comprehensive program is designed to equip you with the essential 
                    skills and knowledge needed to work with DevOps effectively. Whether you're a beginner or an 
                    experienced developer looking to enhance your expertise, this course caters to all levels.
                </p>

                <br />
                <h2>Course Overview</h2>

                <p><strong>Duration</strong>: 3 days <br />
                <strong>Batches</strong>: Weekend <br />
                <strong>Format</strong>: Online<br />
                <strong>Fees</strong>: ₹ 699</p>
                <br />
                <h2 style={{ fontSize: "29px", textDecoration: "underline" }}>Course Curriculum</h2>
                <br />

                <button className='accordion flexSB' onClick={() => toggleAccordion(0)}>
                  <h2>Day-1: Understanding Containerization Concepts</h2>
                  <span>{activeIndex === 0 ? <i className='fa fa-chevron-down'></i> : <i className='fa fa-chevron-right'></i>}</span>
                </button>
                {activeIndex === 0 && (
                  <div className='text'>
                    <ol>
                      <li>Introduction to containerization</li>
                      <li>History and evolution of container technology</li>
                      <li>Key benefits and advantages of containerization</li>
                      <li>Docker as a containerization platform</li>
                      <li>Comparison with virtual machines (VMs)</li>
                    </ol>
                  </div>
                )}

                <button className='accordion flexSB' onClick={() => toggleAccordion(1)}>
                  <h2>Day-2: Docker Architecture and Components</h2>
                  <span>{activeIndex === 1 ? <i className='fa fa-chevron-down'></i> : <i className='fa fa-chevron-right'></i>}</span>
                </button>
                {activeIndex === 1 && (
                  <div className='text'>
                    <ol>
                      <li>Docker Engine: Architecture and components</li>
                      <li>Understanding Docker images and containers</li>
                      <li>Docker registries and repositories</li>
                      <li>Dockerfile: Creating custom Docker images</li>
                      <li>Docker Compose: Managing multi-container applications</li>
                    </ol>
                  </div>
                )}

                <button className='accordion flexSB' onClick={() => toggleAccordion(2)}>
                  <h2>Day-3: Working with Docker Images and Containers</h2>
                  <span>{activeIndex === 2 ? <i className='fa fa-chevron-down'></i> : <i className='fa fa-chevron-right'></i>}</span>
                </button>
                {activeIndex === 2 && (
                  <div className='text'>
                    <ol>
                        <li>Pulling and pushing Docker images from/to registries</li>
                        <li>Building custom Docker images using Dockerfile</li>
                        <li>Running and managing Docker containers</li>
                        <li>Interacting with Docker containers: Executing commands, accessing logs, and more</li>
                        <li>Persisting data in Docker containers: Volumes and bind mounts</li>
                        <li>Docker storage options: Volumes, bind mounts, and tmpfs mounts</li>
                        <li>Container orchestration: Introduction to Docker Swarm and Kubernetes</li>
                    </ol>
                  </div>
                )}
                
                <br />
                <br />
                <h2>Who Should Attend?</h2>
                <p>This course is suitable for:</p>
                <ol>
                    <li>DevOps engineers</li>
                    <li>Software developers</li>
                    <li>Embedded system developers</li>
                    <li>Students pursuing careers in IT industries</li>
                </ol>

                <br />
                <h2>Prerequisites</h2>
                <p>No prior experience with DevOps is required, but a basic understanding of software development and programming concepts would be beneficial.</p>
                
                <br />
                <h2>Course Benefits</h2>

                <br />
                <ol>
                    <li>Gain practical skills to develop and deploy isolated environment using docker.</li>
                    <li>Enhance career prospects in the rapidly growing field of DevOps.</li>
                    <li>Hands-on experience with real-world projects.</li>
                    <li>Access to resources and support from experienced instructors.</li>
                </ol>
                <br />
                <br />
        </div>
      </section>
	    
    <section className="contact-form">
        <h2>ENROLL NOW</h2>
        <form ref={form} onSubmit={handleSubmit}>
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" name="user_name" placeholder="Full Name" required />

          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="user_email" placeholder="Email" required />

          <label htmlFor="message">Message:</label>
          <textarea id="message" name="message" cols="30" rows="10" required></textarea>

          <input type="hidden" id="subject" name="subject" value="Enrollment to Yocto Course" required />

          <button type="submit">Submit</button>
        </form>
      </section>
    </div>
  </>
  );
};

export default Docker;
