import React from "react";
import "./OnlineServices.css";
import { service } from "../../dummydata";
import Heading from "../common/heading/Heading";

const OnlineServices = () => {
  return (
    <>
      <section className='services'>
        <div className='container'>
          <br />
          <br />
          <Heading subtitle='OUR SERVICES' title='WHY CHOOSE US' />
          <p style={{ textAlign:"left" }} >
            At Penguintech, we are passionate about empowering products and software companies with the skills and expertise needed to excel in the world of Embedded Linux. With a strong emphasis on quality, innovation, and hands-on learning, we offer a comprehensive range of services and training programs tailored to meet your needs in the ever-evolving landscape of embedded systems.
            <br />
            <br />
            Lastly, in addition to offering platform-specific training, we also provide comprehensive packages that include all the assistance you need to realize your software development project from start to finish.
          </p>

          <Heading title='WHAT WE DO' />
          <div className='content grid3'>
            {service.map((val, index) => (
              <div
                className={`box ${index % 2 === 0 ? 'white' : 'black'}`}
                key={index}
              >
                <div className='img'>
                  <img src={val.cover} alt='' />
                </div>
                <h1 className={index % 2 === 0 ? 'black-text' : 'white-text'}>{val.serviceName}
                </h1>
                <div
                  className={`${index % 2 === 0 ? 'black-text' : 'white-text'} description`}>{val.desciption}
                </div>
                <div className='line'></div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default OnlineServices;
