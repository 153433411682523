import React, {useEffect} from "react";
import "../Post.css"
import Heading from "../../common/heading/Heading"

import config from "./resources/config.webp"
import boot from "./resources/boot.webp"

const Post7 = () => {

	useEffect(() => {
		window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
	  }, []);

  return (
	<>
	<section className='post'>
		<Heading title='Getting started with Buildroot' />

		<div className='post-body'>
			<p>
				Buildroot, a toolkit for building highly customized embedded Linux images. Buildroot is the right combination of “powerful” and “easy to use.” It provides a lot of automation so that your build process can Just Work, freeing you up for doing the interesting parts of embedded Linux development.
			</p>
			<h2>Pre-requisite</h2>
			<p>
				System requires some packages/build tools pre-installed to start the Buildroot compilation.
			<br />
			<br />
			<code>$ sudo apt install -y git build-essential wget cpio unzip rsync bc libncurses5-dev screen</code>
			</p>
			<h2>Download the Buidroot</h2>
			<p>
				There are two ways to do so either by downloading <a style={{ color: "blue", fontWeight: "bold", textDecoration: "underline" }} href="https://buildroot.org/download.html">tarball released officially</a> or easiest way is to use Git.
			<br />
				checkout on latest release i.e in my case it is 2022.11.x
			</p>
			<h2>Configure the Buildroot for specific hardware</h2>
			<p>
				As we are in top level directory of Buildroot tree we can see that whole project is implemented in make and all interaction happens by calling make.
			<br />
			<br />
				So to configure the build will run <code>$ make menuconfig and will get below configuration window</code>
			<br />
			<br />
			<img style={{marginLeft:"1%", width:"100%"}} src={config} alt="" />
			<br />
			<br />
				Once you wisely tuned the required configurations, save it. It will update the .config file automatically.
			<br />
			<br />
				Run the <strong>make</strong> command and it will build the kernel and file system for those configurations.
			<br />
			<br />
				But what if you don't have any hardware with you !!!
			<br />
			<br />
				Possibly one solution for this is you can still build the image and run into emulated environment (QEMU).
			<br />
			<br />
				Only the thing you need to do is run below command to set-it up configuration for emulated environment.
			<br />
				<code>$ make qemu_x86_64_defconfig</code>
			<br />
			<br />
			it will print output something like this
			<br />
			<pre style={{wordWrap:"break-word", overflow:"auto"}}>
				{`
#
# configuration written to /home/prashant/buildroot/.config
#
				`}
			</pre>
			and then run
			<br />
			<code>$ make</code>
			<br />
			<br />
			For the first time it will take quite more time to build (approx. 40min) and it results in kernel and rootFS created at “output/images” directory.
			<br />
			<pre style={{wordWrap:"break-word", overflow:"auto"}}>
				{`
output
|-- build
|-- host
|-- images
|   |-- bzImage
|   |-- rootfs.ext2
|   \`-- start-qemu.sh
|-- staging -> /home/prashant/buildroot/output/host/x86_64-buildroot-linux-gnu/sysroot
\`-- target

5 directories, 0 files
				`}
      		</pre>
			</p>
			<h2>Booting the created image</h2>
			<p>
				As shown above “start-qemu.sh” script is automatically generated to help you or you can use below command if you want to launch with you manual configuration-
			<br />
			<br />
				<code>
					$ qemu-system-x86_64 -M pc -kernel bzImage -drive file=rootfs.ext2,if=virtio,format=raw -append "rootwait root=/dev/vda console=tty1 console=ttyS0"  -net nic,model=virtio -net user -nographic
				</code>
			<br />
			<br />
				It will start booting and Hola you did it.
			<br />
			<img style={{marginLeft:"1%", width:"100%"}} src={boot} alt="" />
			<br />
				One good thing I like about Buildroot is, it can create very minimal size image around 12 MB rootfs only (i386 arch) as compared to yocto’s core-image-minimal !
			<br />
			<br />
				Hope you liked it and enjoy the learning !
			</p>
		</div>
	</section>
  </>
  );
};

export default Post7;
