import React, { useEffect } from "react";
import Back from "../common/back/Back";
import OnlineServices from "./OnlineServices";
import { useHistory } from "react-router-dom";
import ReactGA from "react-ga4";
import LF_logo from "../../assests/services/Linux_Foundation_logo.webp";
import bash_logo from "../../assests/services/bash.webp";
import yocto_logo from "../../assests/services/Yocto_logo.webp";

const TRACKING_ID = "G-VCW9GEYQ5B"
ReactGA.initialize(TRACKING_ID);


const CourseHome = () => {
/*------------------------------- google analytics ------------------------------*/
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/services", title: "Services Page" });
  }, []);

  /*-------------------------------------------------------------------------------*/

  const history = useHistory();

  const handleButtonClick = () => {
    // Navigate to the /contact page
    history.push("/contact");
  };

  return (
    <>
      <Back title='Services' color="#00dc82" />
      <OnlineServices />
      
      <div className="sticky-button" onClick={handleButtonClick}>
        <button>Avail Service</button>
      </div>

      <div className="flex " style={{marginTop:"15%", marginLeft:"10%", width: "80%",flexDirection:"row", alignItems:"center"}}>               
        <div>
          <img style={{width:"60%"}} decoding="async" alt="" src={LF_logo}/>
        </div>
        <div style={{width:"40%", marginLeft:"-15%"}}>
          <h1>Linux and embedded Linux</h1>
          <br />
          <p>
            We believe in the open-source operating system, which is why we use Linux in our products, support the source code and team have in-depth experience with the industry.
          </p>
        </div>
      </div>

      <div className="flex " style={{marginTop:"15%", marginLeft:"10%", width: "80%",flexDirection:"row-reverse", alignItems:"center"}}>               
        <div>
          <img style={{width:"80%",marginLeft:"25%"}} decoding="async" alt="" src={bash_logo}/>
        </div>
        <div style={{width:"60%", justifyContent:"center"}}>
          <h1>Software integration</h1>
          <br />
          <p>
            We write scripts for your missing components and cross-compile softwares for application integration. Also do linux process management for better performance.
          </p>
        </div>
      </div>

      <div className="flex " style={{marginTop:"15%", marginLeft:"10%", width: "80%",flexDirection:"row", alignItems:"center"}}>               
        <div>
          <img style={{width:"80%"}} decoding="async" alt="" src={yocto_logo}/>
        </div>
        <div style={{width:"40%", marginLeft:"10%", justifyContent:"center"}}>
          <h1>Yocto Engineering</h1>
          <br />
          <p>
            With the open-source yocto project, we will help you with  Yocto setup (board, toolchain, kernel and bootloader), Configuration of Yocto distro features (init system)
            Setting up of Yocto security features, Integration of third-party layers (components), Development of product-specific Yocto meta-layers
            Development of BitBake/Yocto recipes, Compilation of target images and SDK bundles, Update of Yocto-based BSPs (core layer, recipes)
            Container-based Yocto build environment and Review of existing Yocto systems
          </p>
        </div>
      </div>
    </>
  )
}

export default CourseHome
