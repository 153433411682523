import React from "react"
import Back from "../common/back/Back"
import CoursesCard from "./CoursesCard";

const training = () => {
  return (
    <>
      <Back title='Trainings' color="#ff6492"/>
      <CoursesCard />
    </>
  )
}

export default training
