import React from "react"
import Heading from "../common/heading/Heading"
import "./about.css"
import { homeAbout } from "../../dummydata"

import Earth from './globe'

const AboutCard = () => {
  return (
    <>
    <div >
      <section className='aboutHome'>
        <div className='container flexSB'>
          <div className="left-comp" id="aboutCard">
            <Earth />
          </div>

          <div className='right row'>
            <Heading title='Benefits About Our Services Expertise' />
            <div className='items'>
              {homeAbout.map((val) => {
                return (
                  <div className='item flexSB'>
                    <div className='img'>
                      <img src={val.cover} alt='' />
                    </div>
                    <div className='text' style={{marginLeft:"12px"}}>
                      <h2>{val.title}</h2>
                      <p>{val.desc}</p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>
    </div>
    </>
  )
}

export default AboutCard
