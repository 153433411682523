import React from "react"
import Heading from "../../common/heading/Heading"
import "./Hero.css"
import AnimatedShapes from "../../AnimatedShapes/AnimatedShapes";
import SDE from "./home.png" // Home screen image
import styled from "styled-components";
import { useTypingText } from "./useTypingtext";

import AnimatedButton from './AnimatedButton';

const Hero = () => {
  // words for offerrings-texts
  const { word } = useTypingText(
    ["Embedded Linux", "Yocto project", "Linux BSP", "DevOps"],
    50,
    20,
  );

  return (
    <>
      <section className='hero'>
        <Image
          src={SDE}
          loading="lazy"
          srcSet={`${SDE} 320w, ${SDE} 480w, ${SDE} 800w`}
          sizes="(max-width: 768px) 30rem, 50rem"
          alt=""
        />
        <AnimatedShapes />
          <div className='container'>
            <Heading title='Empowering Tech Mastery' />
              <h2 style={{ fontSize: "30px" }}>
                Your Gateway to {word}
              </h2>
          </div>
        </section>
        <ButtonContainer>
          <AnimatedButton/>
        </ButtonContainer>

        <svg className="arrows">
              <path className="a1" d="M0 0 L30 32 L60 0"></path>
              <path className="a2" d="M0 20 L30 52 L60 20"></path>
              <path className="a3" d="M0 40 L30 72 L60 40"></path>
        </svg>
    </>
  );
}

// keep the same position of button w.r.t. the hero section
const ButtonContainer = styled.div`
  position: absolute;
  top: 60%;
  left: 14%;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-left:40%;
    margin-top:-15%
   };
`;

const Image = styled.img`
width:50rem;
position:absolute;
bottom:0;
right:0%;
top:32.5%;

@media only screen and (max-width: 768px) {
  right:-25%;
  margin-top:34%;
  width: 30rem;
  padding: 0
 };
 `;
export default Hero
