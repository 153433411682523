// src/routes/finished.jsx
 
import { useRef } from 'react';
import Globe from 'react-globe.gl';
import * as THREE from 'three';
import * as topojson from 'topojson-client';
 
import landTopology from '../../assests/globe/land_10m.json';
import pointsData from '../../assests/globe/random-locations.json';
import texture from '../../assests/globe/mytext.png';
 
const min = 800;
const max = 4000;
const sliceData = pointsData.sort(() => (Math.random() > 0.5 ? 1 : -1)).slice(20, 90);
 
const arcsData = sliceData.map(() => {
  const randStart = Math.floor(Math.random() * sliceData.length);
  const randEnd = Math.floor(Math.random() * sliceData.length);
  const randTime = Math.floor(Math.random() * (max - min + 1) + min);
  return {
    startLat: sliceData[randStart].lat,
    startLng: sliceData[randStart].lng,
    endLat: sliceData[randEnd].lat,
    endLng: sliceData[randEnd].lng,
    time: randTime,
    color: ['#5e409f80', '#faf7e6', '#5e409f80'],   // this is arc gradient start-middle-end color
  };
});
 
const Page = () => {
  const globeRef = useRef(null);
 
  const globeReady = () => {
    if (globeRef.current) {
      globeRef.current.controls().autoRotate = true;
      globeRef.current.controls().enableZoom = false;
 
      globeRef.current.pointOfView({
        lat: 20.59,
        lng: 78.96,
        altitude: 1.8,
      });

    }
  };
 
  return (
    <div className='cursor-move'>
      <Globe
        ref={globeRef}
        onGlobeReady={globeReady}
        backgroundColor='#f8f8f8'  // background of earth
        rendererConfig={{ antialias: true, alpha: true }}
        globeMaterial={
          new THREE.MeshPhongMaterial({
            color: "#d9f2fc",   // outside land - water part color
            opacity: 0.25,
            transparent: false,
          })
        }
        width={800}
        height={800}
        atmosphereColor='#6dbcea'  // glow near the earth
        atmosphereAltitude={0.22}
        pointsMerge={true}
        pointsData={pointsData}
        pointAltitude={0.01}
        pointRadius={0.1}
        pointResolution={5}
        pointColor={() => '#eed31f'}  // highlighted points on land in yellow color
        arcsData={arcsData}
        arcAltitudeAutoScale={0.3}
        arcColor='color'
        arcStroke={0.5}
        arcDashGap={4}
        arcDashAnimateTime='time'
        polygonsData={topojson.feature(landTopology, landTopology.objects.land).features}
        polygonSideColor={() => '#00000000'}   //land area border
        polygonCapMaterial={
          new THREE.MeshPhongMaterial({
            color: "#6DBCEA", //land area color
            side: THREE.DoubleSide,
            map: new THREE.TextureLoader().load(texture),
          })
        }
        polygonAltitude={0.01}
        customLayerData={[...Array(500).keys()].map(() => ({
          lat: (Math.random() - 1) * 360,
          lng: (Math.random() - 1) * 360,
          altitude: Math.random() * 2,
          size: Math.random() * 0.4,
          color: "#753651", // moving particles
        }))}
        customThreeObject={(sliceData) => {
          const { size, color } = sliceData;
          return new THREE.Mesh(new THREE.SphereGeometry(size), new THREE.MeshBasicMaterial({ color }));
        }}
        customThreeObjectUpdate={(obj, sliceData) => {
          const { lat, lng, altitude } = sliceData;
          return Object.assign(obj.position, globeRef.current?.getCoords(lat, lng, altitude));
        }}
      />
    </div>
  );
};
 
export default Page;