import React, {useEffect} from "react";
import "../Post.css"
import Heading from "../../common/heading/Heading"

import config from "./resources/config.webp"
import boot from "./resources/boot.webp"

const Post8 = () => {

	useEffect(() => {
		window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
	  }, []);

  return (
	<>
	<section className='post'>
		<Heading title='Upgrading to Yocto langdale' />

		<div className='post-body'>
		<p>The Yocto 4.1 release (fall 2022), also codenamed “Langdale” introduces a
		couple of changes.
		
		<br />

		The following errors and fixes have appeared while attempting to update a
		meta-layer to the current release.</p>

		<hr />
		<br />
		<hr />
		<br />
		<h2 id="errors">Errors</h2>
		<br />
		<hr />
		<br />

		<h2 id="incorrect-layerseries_compat">Incorrect LAYERSERIES_COMPAT</h2>

		<p><strong>Error message:</strong></p>
		<code style={{background:"black",color: "white", padding: "10px"}}>ERROR: Layer meta-amateurradio is not compatible with the core layer which only supports these series: langdale (layer is compatible with kirkstone)
		</code>

		<p><strong>Explanation:</strong>
		Each layer must declare the list of releases it is compatible with, to
		safeguard against mismatched configurations. See the upstream documentation
		about <a style={{color:"blue"}}href="https://docs.yoctoproject.org/ref-manual/variables.html#term-LAYERSERIES_COMPAT">LAYERSERIES_COMPAT</a>.
		Because we're working with new Yocto versions, we'll have to manually change
		the list of the compatible releases of the layer, inside the <code>layer.conf</code> file.</p>

		<p><strong>Fix:</strong></p>
		<code style={{background:"black",color: "white", padding: "10px"}}>vim poky/meta-amateurradio/conf/layer.conf</code>
		<br />
		<br />

        <code style={{background:"black",color: "white", padding: "10px"}}>
            <a style={{color:"red"}}>--- a/conf/layer.conf</a> <br />
            <a style={{color:"#a2de2a"}}>+++ b/conf/layer.conf</a> <br />
            <a style={{color:"yellow"}}>@@ -12,7 +12,7 @@</a> <br />
            BBFILE_PRIORITY_meta-sirius = "6" <br />
            LAYERDEPENDS_meta-sirius = "core" <br />
            LAYERDEPENDS_meta-sirius += "openembedded-layer" <br />

            <a style={{color:"red"}}>-LAYERSERIES_COMPAT_meta-sirius = "kirkstone"</a> <br />
            <a style={{color:"#a2de2a"}}>+LAYERSERIES_COMPAT_meta-sirius = "kirkstone langdale"</a> <br />
            
            BBFILES_DYNAMIC += " \
            qt5-layer:$LAYERDIR/dynamic-layers/qt5-layer/*/*/*.bb \
        </code>
		<br />
		<br />
		<hr />

		<p><strong>Error message:</strong></p>
		<code style={{background:"black",color: "white", padding: "10px"}}>ERROR: Nothing RPROVIDES 'linux-firmware-rpidistro-bcm43456' (but /.../build/../poky/meta/recipes-core/packagegroups/packagegroup-base.bb RDEPENDS on or otherwise requires it)
		linux-firmware-rpidistro RPROVIDES linux-firmware-rpidistro-bcm43456 but was skipped: because it has a restricted license 'synaptics-killswitch'. Which is not listed in LICENSE_FLAGS_ACCEPTED
		</code>

		<p><strong>Explanation:</strong></p>
		The <code>linux-firmware-rpidistro</code> package provided by the meta-raspberrypi layer has
		components licensed under more restrictive terms. This license must be explicitly
		enabled.
		Read more at <a href="https://meta-raspberrypi.readthedocs.io/en/latest/ipcompliance.html">https://meta-raspberrypi.readthedocs.io/en/latest/ipcompliance.html</a>.

		<p><strong>Fix:</strong></p>
		<br />
		<code style={{background:"black",color: "white", padding: "10px"}}>vim build/conf/local.conf</code>
		<br />
		<br />

		<code style={{background:"black",color: "white", padding: "10px"}}>EXTRA_IMAGE_FEATURES = "debug-tweaks ssh-server-dropbear package-management"
		DISTRO_FEATURES:append = " wifi polkit"
		<br />
		<br />
		+LICENSE_FLAGS_ACCEPTED = "synaptics-killswitch"
		</code>
		</div>
	</section>
  </>
  );
};

export default Post8;
