import React from "react"

const Back = ({ title, color }) => {

  return (
    <>
      <section className="back">
        <ul>
          <li style={{ '--clr': color }}>
            <a href="#" data-text={` ${title}`}>&nbsp;{title}&nbsp;</a>
          </li>
        </ul>
      </section>
      <div className="margin"></div>
    </>
  );
};

export default Back;
