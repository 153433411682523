import React from "react";
import { coursesCard } from "../../dummydata";
import Heading from "../common/heading/Heading";
import { useHistory } from "react-router-dom";

import "./CourseCard.css"

const CoursesCard = () => {

  const history = useHistory();

  const handleClickDetails = (index) => {
    history.push(`/course/${index + 1}`);
  };

  return (
    <>
      <section className="Card">
        <br />
        <br />
        <Heading subtitle="LEARN WITH US" title="PROFESSIONAL TRAININGS" />
        <div className="container grid3">
          {coursesCard.map((val, index) => (
                <div key={index} className="categoryWrapper">
                  {index === 2 && <div className="free-label"></div>}
                      <div className="image">
                          <img src={val.cover} alt="" />
                      </div>
                  <h2 style={{marginRight:"10px"}}>{val.coursesName}</h2>
                    <button  onClick={() => handleClickDetails(index)}>
                      <span>
                        <span>
                          <span data-attr-span="Click Me">
                            See the details
                          </span>
                        </span>
                      </span>
                    </button>
                  </div>
              ))}
        </div>
      </section>
      <br />
      <br />
      <br />
      <br />
    </>
  );
};

export default CoursesCard;
