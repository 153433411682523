import React, { useRef, useEffect } from "react";
import Back from "../common/back/Back";
import emailjs from "emailjs-com";
import "./contact.css";
import ReactGA from "react-ga4";

const TRACKING_ID = "G-VCW9GEYQ5B";
ReactGA.initialize(TRACKING_ID);


const Contact = () => {

  /*------------------------------- google analytics ------------------------------*/
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/contact", title: "Contact Page" });
  }, []);

  /*-------------------------------------------------------------------------------*/

  const form = useRef();

  const serviceID = "service_e9srhaj";
  const templateID = "template_ru14an4";
  const userID = "TT6uRLKbvTYo5Za4g";

  const handleSubmit = (e) => {
      e.preventDefault();
      e.preventDefault();
      // Send form data using EmailJS
    e.preventDefault();
      // Send form data using EmailJS
    emailjs
      .sendForm(serviceID, templateID, e.target, userID)
      .then(
        (result) => {
          console.log("Email sent successfully:", result.text);
          alert("Your message has been sent successfully!");
        },
        (error) => {
          console.error("Email send failed:", error.text);
          alert("Failed to send message. Please try again later.");
        }
      );

    e.target.reset();
  };

  useEffect(() => {
    const initMap = () => {
      const map = new window.google.maps.Map(document.getElementById('map'), {
        center: { lat: 12.9716, lng: 77.5946 },
        zoom: 12,
        mapTypeControl: false,
        disableDefaultUI: true,
        styles: [
          { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
          { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
          { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
          { featureType: 'administrative.locality', elementType: 'labels.text.fill', stylers: [{ color: '#d59563' }] },
          { featureType: 'poi', elementType: 'labels.text.fill', stylers: [{ color: '#d59563' }] },
          { featureType: 'poi.park', elementType: 'geometry', stylers: [{ color: '#263c3f' }] },
          { featureType: 'poi.park', elementType: 'labels.text.fill', stylers: [{ color: '#6b9a76' }] },
          { featureType: 'road', elementType: 'geometry', stylers: [{ color: '#38414e' }] },
          { featureType: 'road', elementType: 'geometry.stroke', stylers: [{ color: '#212a37' }] },
          { featureType: 'road', elementType: 'labels.text.fill', stylers: [{ color: '#9ca5b3' }] },
          { featureType: 'road.highway', elementType: 'geometry', stylers: [{ color: '#746855' }] },
          { featureType: 'road.highway', elementType: 'geometry.stroke', stylers: [{ color: '#1f2835' }] },
          { featureType: 'road.highway', elementType: 'labels.text.fill', stylers: [{ color: '#f3d19c' }] },
          { featureType: 'transit', elementType: 'geometry', stylers: [{ color: '#2f3948' }] },
          { featureType: 'transit.station', elementType: 'labels.text.fill', stylers: [{ color: '#d59563' }] },
          { featureType: 'water', elementType: 'geometry', stylers: [{ color: '#17263c' }] },
          { featureType: 'water', elementType: 'labels.text.fill', stylers: [{ color: '#515c6d' }] },
          { featureType: 'water', elementType: 'labels.text.stroke', stylers: [{ color: '#17263c' }] }
        ]
      });

      // Add a marker to the map
      new window.google.maps.Marker({
        position: { lat: 12.9716, lng: 77.5946 },
        map: map,
        title: "Bangalore",
        icon: {
          url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png"
        }
      });
    };

    if (window.google && window.google.maps) {
      initMap();
    } else {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDts9C9iWNF83ExezBXJLIJ0g2dwoERg08&callback=initMap`;
      script.async = true;
      script.defer = true;
      script.onload = initMap;
      document.head.appendChild(script);
    }
  }, []);

  return (
    <>
      <Back title='Contact' color="#ff6492" />
      <section className="contacts padding">
        <div className="container shadow flexSB row">
          <div className="left">
            <h1>Contact us</h1>

            <br />

            <p style={{ color: "#ffff", textAlign:"center"}}>We're open for any suggestion or just to have a chat</p>

            <br />
            <br />

            <div className="items grid2">
              <div className="box">
                <h4 style={{ color: "#d59563"}}>ADDRESS:</h4>
                <p style={{ color: "#ffff"}}>Bengaluru, India. 560103</p>
              </div>
              <div className="box">
                <h4 style={{ color: "#d59563"}}>EMAIL:</h4>
                <p style={{ color: "#ffff"}}>info@thepenguintech.com</p>
              </div>
              <div className="box">
                <h4 style={{ color: "#d59563"}}>PHONE:</h4>
                <p style={{ color: "#ffff"}}>+91 7028193787</p>
              </div>
            </div>

            <div className="formbox">
              <form ref={form} onSubmit={handleSubmit}>
                <input name="user_name" type="text" className="short" placeholder="Name" onFocus={(e) => e.target.placeholder = ''} onBlur={(e) => e.target.placeholder = 'Name'} />
                <input name="user_email" type="email" className="short" placeholder="Email" onFocus={(e) => e.target.placeholder = ''} onBlur={(e) => e.target.placeholder = 'Email'} />
                <input name="subject" type="text" className="feedback-input" placeholder="Subject" onFocus={(e) => e.target.placeholder = ''} onBlur={(e) => e.target.placeholder = 'Subject'} />
                <textarea name="message" className="feedback-input" placeholder="Message" onFocus={(e) => e.target.placeholder = ''} onBlur={(e) => e.target.placeholder = 'Message'}></textarea>
                <input type="submit" value="SEND" />
              </form>
            </div>
          </div>
          <div className="right">
            <div id="map"></div>
            <div id="map-overlap">
              Bengaluru, <br />
              India. <br />
              560103 <br />
              India
            </div>
          </div>
          <div className="cleared"></div>
        </div>
      </section>
    </>
  );
};

export default Contact;
