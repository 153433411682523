import React, {useEffect} from "react"
import Back from "../common/back/Back"
import BlogCard from "./BlogCard"
import "./blog.css"
import ReactGA from "react-ga4";

const TRACKING_ID = "G-VCW9GEYQ5B"
ReactGA.initialize(TRACKING_ID);

const Blog = () => {
  /*------------------------------- google analytics ------------------------------*/
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/blogs", title: "Blogs Page" });
  }, []);

  /*-------------------------------------------------------------------------------*/
  return (
    <>
      <Back title='Blogs' color="#ffdd1c"/>
      <section className='blog padding'>
        <div className='container grid2'>
          <BlogCard />
        </div>
      </section>
    </>
  )
}

export default Blog
